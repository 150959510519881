.left-top-medium-spacing {
    padding-left: 16px;
    padding-top: 16px;
}
.helpView {
    z-index: 40;
    position: absolute;
}

.appScanner {
    position: absolute;
    z-index: -100;
}

.loader {
    /* Full screen loader overlay */
    width: 100%;
    height: 100%;
    z-index: 30;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
}

.backdrop {
    background-color: rgba(11, 12, 12, 0.95);
    z-index: 25;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}
